import { useEffect, useState } from "react";
import styles from "./ModalCreateCollection.module.scss";
import { useNavigate } from "react-router-dom";
import { PrepopulateFilterFlags, PrepopulateSet, allFoilFlags, allRarityFlags, checkPrepopulateFlags, doesPrepopulateSetHaveColdFoils, doesPrepopulateSetHaveMarvels, doesPrepopulateSetHaveSuperRares, isPromoSet, mergePrepopulateFlags, removePrepopulateFlags, useCollectionCreate, useCreatePrepopulatedCollection } from "@fyendalscollection/shared";
import { useFocus } from "@fyendalscollection/app/lib/useFocus";
import { Modal } from "@fyendalscollection/app/lib/components/Modal";
import { Loadable } from "@fyendalscollection/app/lib/components/Loadable";
import { TitleSecondary } from "@fyendalscollection/app/lib/components/TitleSecondary";
import { Paragraph } from "@fyendalscollection/app/lib/components/Paragraph";
import { Container, ContainerSegment } from "@fyendalscollection/app/lib/components/Container";
import { TextEntry } from "@fyendalscollection/app/lib/components/TextEntry";
import { ContainerListItemRadioButton } from "@fyendalscollection/app/lib/components/ContainerListItemRadioButton";
import { Checkbox } from "@fyendalscollection/app/lib/components/Checkbox";
import { Selector } from "@fyendalscollection/app/lib/components/Selector";
import { PremiumErrorDisplay } from "@fyendalscollection/app/lib/components/PremiumErrorDisplay";
import { ErrorDisplay } from "@fyendalscollection/app/lib/components/ErrorDisplay";
import { GreenButtonSet } from "@fyendalscollection/app/lib/components/GreenButtonSet";
import { NumericEntry } from "@fyendalscollection/app/lib/components/NumericEntry";

export interface ModalCreateCollection {
	visible: boolean;
	onCancel: () => void;
}

export const ModalCreateCollection = (props: ModalCreateCollection): JSX.Element => {
	const [ref, focus] = useFocus();

	const createCollection = useCollectionCreate();
	const createPrepopulatedCollection = useCreatePrepopulatedCollection();
	const navigate = useNavigate();

	// Focus the value TextEntry when we land on the page.
	useEffect(() => {
		if (props.visible) {
			focus();
			setName("");
		}
	}, [props.visible]);

	useEffect(() => {
		if (createPrepopulatedCollection.isSuccess) {
			navigate("/collections/" + createPrepopulatedCollection.data.body.collectionId);
		}
	}, [createPrepopulatedCollection.isSuccess]);

	useEffect(() => {
		if (createCollection.isSuccess) {
			navigate("/collections/" + createCollection.data.body.collectionId);
		}
	}, [createCollection.isSuccess]);

	const [name, setName] = useState("");
	const [prepopulated, setPrepopulated] = useState(false);
	const [prepopulateFlags, setPrepopulateFlags] = useState(allRarityFlags);
	const [sets, setSets] = useState<PrepopulateSet[]>([]);
	const [quantity, setQuantity] = useState(1);
	const [forceEquipmentWeaponQuantity, setForceEquipmentWeaponQuantity] = useState(true);
	const [forceColdFoilQuantity, setForceColdFoilQuantity] = useState(true);

	const getValidPrepopulateFlags = (setsHaveColdFoils: boolean, setsHaveSuperRares: boolean, setsHaveMarvels: boolean, setsHavePromos: boolean) => {
		let validFlags = prepopulateFlags;
		validFlags = removePrepopulateFlags(validFlags, setsHaveColdFoils ? PrepopulateFilterFlags.None : PrepopulateFilterFlags.ColdFoil);
		validFlags = removePrepopulateFlags(validFlags, setsHaveSuperRares ? PrepopulateFilterFlags.None : PrepopulateFilterFlags.SuperRare);
		validFlags = removePrepopulateFlags(validFlags, setsHaveMarvels ? PrepopulateFilterFlags.None : PrepopulateFilterFlags.Marvel);
		validFlags = removePrepopulateFlags(validFlags, setsHavePromos ? PrepopulateFilterFlags.None : PrepopulateFilterFlags.Promo);
		return validFlags;
	};

	const setsHaveColdFoils = !!sets.find(set => doesPrepopulateSetHaveColdFoils(set));
	const setsHaveSuperRares = !!sets.find(set => doesPrepopulateSetHaveSuperRares(set));
	const setsHaveMarvels = !!sets.find(set => doesPrepopulateSetHaveMarvels(set));
	const setsHavePromos = !!sets.find(set => isPromoSet(set));
	const validPrepopulateFlags = getValidPrepopulateFlags(setsHaveColdFoils, setsHaveSuperRares, setsHaveMarvels, setsHavePromos);

	const onFilterChange = (flags: PrepopulateFilterFlags) => {
		return (value: boolean) => {
			if (value) {
				setPrepopulateFlags(mergePrepopulateFlags(prepopulateFlags, flags));
			} else {
				setPrepopulateFlags(removePrepopulateFlags(prepopulateFlags, flags));
			}
		};
	};

	const onConfirm = () => {
		if (prepopulated) {
			createPrepopulatedCollection.mutate({
				name: name,
				sets: sets,
				filterFlags: validPrepopulateFlags,
				quantity: quantity,
				forceEquipmentWeaponQuantity: forceEquipmentWeaponQuantity,
				forceColdFoilQuantity: forceColdFoilQuantity
			});
		} else {
			createCollection.mutate({
				name: name
			});
		}
	};

	const hasSelectedFoiling = (validPrepopulateFlags & allFoilFlags) !== PrepopulateFilterFlags.None;
	const hasSelectedRarity = (validPrepopulateFlags & allRarityFlags) !== PrepopulateFilterFlags.None;

	return (
		<Modal visible={props.visible} onCancel={props.onCancel}>
			<Loadable loading={createPrepopulatedCollection.isLoading || createCollection.isLoading}>
				<TitleSecondary text={"Create Collection"} />

				<Paragraph>
					{"Please enter a name for your new collection"}
				</Paragraph>

				<Container>
					<ContainerSegment>
						<TextEntry
							ref={ref}
							placeholder="Enter Here"
							value={name}
							onChange={setName}
							onEnter={!prepopulated ? onConfirm : undefined} />
					</ContainerSegment>
				</Container>

				<Container>
					<ContainerSegment>
						<ContainerListItemRadioButton
							title="Empty"
							subtitle="Create a new, empty collection"
							onClick={() => setPrepopulated(false)}
							selected={!prepopulated} />
					</ContainerSegment>
					<ContainerSegment>
						<ContainerListItemRadioButton
							title="Pre-populated"
							subtitle="Create a new collection with cards that match a set of filters"
							onClick={() => setPrepopulated(true)}
							selected={prepopulated} />
					</ContainerSegment>
				</Container>

				{
					prepopulated &&
					<>
						<Container title="Sets">
							<ContainerSegment>
								<Selector values={sets} onChange={setSets} optionsDescription={"set"} options={[
									{
										text: "Welcome to Rathe 1st Edition",
										value: PrepopulateSet.Wtr1stEdition
									},
									{
										text: "Welcome to Rathe Unlimited",
										value: PrepopulateSet.WtrUnlimitedEdition
									},
									{
										text: "Arcane Rising 1st Edition",
										value: PrepopulateSet.Arc1stEdition
									},
									{
										text: "Arcane Rising Unlimited",
										value: PrepopulateSet.ArcUnlimitedEdition
									},
									{
										text: "Crucible of War 1st Edition",
										value: PrepopulateSet.Cru1stEdition
									},
									{
										text: "Crucible of War Unlimited",
										value: PrepopulateSet.CruUnlimitedEdition
									},
									{
										text: "Monarch 1st Edition",
										value: PrepopulateSet.Mon1stEdition
									},
									{
										text: "Monarch Unlimited",
										value: PrepopulateSet.MonUnlimitedEdition
									},
									{
										text: "Tales of Aria 1st Edition",
										value: PrepopulateSet.Ele1stEdition
									},
									{
										text: "Tales of Aria Unlimited",
										value: PrepopulateSet.EleUnlimitedEdition
									},
									{
										text: "Everfest 1st Edition",
										value: PrepopulateSet.Evr1stEdition
									},
									{
										text: "Uprising",
										value: PrepopulateSet.Upr
									},
									{
										text: "Dynasty",
										value: PrepopulateSet.Dyn
									},
									{
										text: "Outsiders",
										value: PrepopulateSet.Out
									},
									{
										text: "Dusk till Dawn",
										value: PrepopulateSet.Dtd
									},
									{
										text: "Bright Lights",
										value: PrepopulateSet.Evo
									},
									{
										text: "Heavy Hitters",
										value: PrepopulateSet.Hvy
									},
									{
										text: "Part the Mistveil",
										value: PrepopulateSet.Mst
									},
									{
										text: "Rosetta",
										value: PrepopulateSet.Ros
									},
									{
										text: "Local Game Store Promos",
										value: PrepopulateSet.Lgs
									},
									{
										text: "Hero Card Promos",
										value: PrepopulateSet.Her
									},
									{
										text: "Tournament Legal Promos",
										value: PrepopulateSet.Fab
									},
									{
										text: "Judge Promos",
										value: PrepopulateSet.Jdg
									},
									{
										text: "Non-Tournament Legal Promos",
										value: PrepopulateSet.Lss
									}
								]} />
							</ContainerSegment>
						</Container>

						<Container title="Foiling">
							<div className={styles.checkBoxes}>
								<Checkbox
									label="Cold Foils"
									onChange={onFilterChange(PrepopulateFilterFlags.ColdFoil)}
									isChecked={checkPrepopulateFlags(validPrepopulateFlags, PrepopulateFilterFlags.ColdFoil)}
									disabled={!setsHaveColdFoils} />
								<Checkbox
									label="Rainbow Foils"
									onChange={onFilterChange(PrepopulateFilterFlags.RainbowFoil)}
									isChecked={checkPrepopulateFlags(validPrepopulateFlags, PrepopulateFilterFlags.RainbowFoil)} />
								<Checkbox
									label="Non Foils"
									onChange={onFilterChange(PrepopulateFilterFlags.NonFoil)}
									isChecked={checkPrepopulateFlags(validPrepopulateFlags, PrepopulateFilterFlags.NonFoil)} />
							</div>
						</Container>

						<Container title="Rarity">
							<div className={styles.checkBoxes}>
								<Checkbox
									label="Common"
									onChange={onFilterChange(PrepopulateFilterFlags.Common)}
									isChecked={checkPrepopulateFlags(validPrepopulateFlags, PrepopulateFilterFlags.Common)} />
								<Checkbox
									label="Rare"
									onChange={onFilterChange(PrepopulateFilterFlags.Rare)}
									isChecked={checkPrepopulateFlags(validPrepopulateFlags, PrepopulateFilterFlags.Rare)} />
								<Checkbox
									label="Super Rare"
									onChange={onFilterChange(PrepopulateFilterFlags.SuperRare)}
									isChecked={checkPrepopulateFlags(validPrepopulateFlags, PrepopulateFilterFlags.SuperRare)}
									disabled={!setsHaveSuperRares} />
								<Checkbox
									label="Majestic"
									onChange={onFilterChange(PrepopulateFilterFlags.Majestic)}
									isChecked={checkPrepopulateFlags(validPrepopulateFlags, PrepopulateFilterFlags.Majestic)} />
								<Checkbox
									label="Marvel"
									onChange={onFilterChange(PrepopulateFilterFlags.Marvel)}
									isChecked={checkPrepopulateFlags(validPrepopulateFlags, PrepopulateFilterFlags.Marvel)}
									disabled={!setsHaveMarvels} />
								<Checkbox
									label="Legendary"
									onChange={onFilterChange(PrepopulateFilterFlags.Legendary)}
									isChecked={checkPrepopulateFlags(validPrepopulateFlags, PrepopulateFilterFlags.Legendary)} />
								<Checkbox
									label="Fabled"
									onChange={onFilterChange(PrepopulateFilterFlags.Fabled)}
									isChecked={checkPrepopulateFlags(validPrepopulateFlags, PrepopulateFilterFlags.Fabled)} />
								<Checkbox
									label="Promo"
									onChange={onFilterChange(PrepopulateFilterFlags.Promo)}
									isChecked={checkPrepopulateFlags(validPrepopulateFlags, PrepopulateFilterFlags.Promo)}
									disabled={!setsHavePromos} />
							</div>
						</Container>

						<Container title="Quantity Per Card">
							<ContainerSegment>
								<NumericEntry
									value={quantity}
									onChange={setQuantity}
									maxDecimalPlaces={0}
									minValue={1}
									maxValue={10000} />
							</ContainerSegment>
							<ContainerSegment>
								<Checkbox
									label="Force Equipment and Weapons to have quantity of one"
									onChange={setForceEquipmentWeaponQuantity}
									isChecked={forceEquipmentWeaponQuantity && quantity !== 1}
									disabled={quantity === 1}/>
							</ContainerSegment>
							<ContainerSegment>
								<Checkbox
									label="Force cold foils to have quantity of one"
									onChange={setForceColdFoilQuantity}
									isChecked={forceColdFoilQuantity && quantity !== 1 && setsHaveColdFoils && checkPrepopulateFlags(validPrepopulateFlags, PrepopulateFilterFlags.ColdFoil)}
									disabled={quantity === 1 || !setsHaveColdFoils || !checkPrepopulateFlags(validPrepopulateFlags, PrepopulateFilterFlags.ColdFoil)}/>
							</ContainerSegment>
						</Container>

						{
							createPrepopulatedCollection.isError && (
								createPrepopulatedCollection.error.problem.problemType == "PremiumAccessRequired" ?
									<PremiumErrorDisplay problemResponse={createPrepopulatedCollection.error}/>
									:
									<Container>
										<ContainerSegment>
											<ErrorDisplay problemResponse={createPrepopulatedCollection.error}/>
										</ContainerSegment>
									</Container>
							)
						}
					</>
				}

				{
					createCollection.isError && !prepopulated && (
						createCollection.error.problem.problemType === "PremiumAccessRequired" ?
							<PremiumErrorDisplay problemResponse={createCollection.error}/>
							:
							<Container>
								<ContainerSegment>
									<ErrorDisplay problemResponse={createCollection.error}/>
								</ContainerSegment>
							</Container>
					)
				}

				<GreenButtonSet buttons={[
					{
						text: "Confirm",
						disabled: !name.trim() || ((sets.length == 0 || !hasSelectedFoiling || !hasSelectedRarity) && prepopulated),
						onClick: onConfirm
					}
				]} />
			</Loadable>
		</Modal>
	);
};
